import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  // Last click event performed on the page will be recorded here
  public documentClickedEvent: Subject<MouseEvent> = new Subject<MouseEvent>();

  /**
   * Returns an array of all the DOM tagNames that lead to the target element.
   * @param target target
   */
  getPath(target: HTMLElement): Array<any> {
    let current = target;
    const path = [{ tagName: current.tagName }];
    while (current.parentElement) {
      current = current.parentElement;
      path.push({ tagName: current.tagName });
    }

    return path;
  }
}
